











































































































































































































































import PageHeader from '~/components/app/page-header.vue'
import {Component, Watch} from 'vue-property-decorator'
import {
    Course,
    GroupClasses,
    TimeSlot,
    WhatsAppGroup,
    WhatsAppGroupMember
} from '~/components/data-class/data-class'
import {AdminPermission} from '~/utils/permissions'
import {createRequest} from '~/utils/network-request'
import {mixins} from 'vue-class-component'
import QueryParserMixin from '~/mixins/query-parser-mixin'
import PaginationMixin from '~/mixins/pagination-mixin'
import LoadingMixin from '~/mixins/loading-mixin'
import moment from 'moment'
import {OfflineClass} from "~/components/course/offline-course-model";
import OfflineClassStructuredDisplay from "~/components/course/offline-class-structured-display.vue";

@Component({
    components: {
        PageHeader,
        OfflineClassStructuredDisplay
    }
})

export default class WhatsAppGroupManager extends mixins(QueryParserMixin, PaginationMixin, LoadingMixin) {
    static permission = AdminPermission.SendWhatsapp

    keyword: string = ''
    step = 20

    groups: WhatsAppGroup[] = []
    activeGroup: WhatsAppGroup = new WhatsAppGroup

    showAddPhones: boolean = false
    addPhones: string = ''

    showSendMsg: boolean = false
    msg: string = ''

    showEditCourse: boolean = false
    editingGroupClassesIndex: number = -1 // -1 means new group classes.
    course: Course = new Course
    courseId: string = ''
    classes: number[] = []

    newGroup: boolean = false

    drawerVisible: boolean = false

    async created() {
        await this.updateGroups()
    }
    async toPage(page: number) {
        this.currentPage = page
        return this.updateGroups(page)
    }
    async updateGroups(page: number = 1) {
        const res = await createRequest('/chat-api/whatsapp-groups', 'get', {
            keyword: this.keyword,
            limit: this.step,
            skip: (page - 1) * this.step
        }).send()
        this.groups = res.data.groups
        this.totalCount = res.data.count

        const updateActiveGroup = this.groups.find((g) => g._id === this.activeGroup._id)
        if (updateActiveGroup) {
            this.activeGroup = updateActiveGroup
        }
    }

    async keywordChanged() {
        this.currentPage = 1
        await this.updateGroups(1)
    }

    async rowClicked(row) {
        this.activeGroup = row
        this.newGroup = false

        this.drawerVisible = true
    }

    createNewGroup() {
        // (this.$refs.groupTable as any).setCurrentRow()
        this.newGroup = true
        this.activeGroup = new WhatsAppGroup()

        this.drawerVisible = true
    }

    cancelCreateNewGroup() {
        this.newGroup = false
        this.drawerVisible = false
    }
    async submitGroup() {
        let phones: string[] = []
        if (this.addPhones) {
            phones = this.addPhones.split(/\n|,/).map((p) => {
                return p.replace(/ /g, '').trim()
            })
        }
        if (this.newGroup) {
            this.newGroup = false
            this.drawerVisible = false
            const body: any = {
                name: this.activeGroup.name,
                phones: phones
            };

            await createRequest('/chat-api/whatsapp-group', 'post', {}, body).send()
            await this.updateGroups()
            await this.$message.info('Registered, please wait for ChatAPI')
        } else if (phones.length) {
            await createRequest(`/chat-api/whatsapp-group/${this.activeGroup._id}/participant`, 'post', {}, {
                phones: phones
            }).send().then().catch()
            await this.updateGroups(this.currentPage)
            this.addPhones = ''
            this.activeGroup.tobe_added.push(...phones)
            await this.$message.info('Registered, please wait for ChatAPI')
            this.showAddPhones = false
        } else {
            await this.$message.success('No valid phones')
        }
    }

    dsync = false
    async sync() {
        this.dsync = true
        this.startLoading('#sync-btn')
        await createRequest(`/chat-api/whatsapp-group/sync`, 'post').send()
            .finally(() => {
                this.stopLoading('#sync-btn')
                this.dsync = false
            })
        await this.updateGroups()
    }
    async removePhone(phone) {
        await createRequest(`/chat-api/whatsapp-group/${this.activeGroup._id}/participant`, 'delete', {}, {
            phone
        }).send().then().catch()
        await this.$message.info('Registered, please wait for ChatAPI')
        await this.updateGroups(this.currentPage)
    }

    phonesInputed(v: string) {
        const after = v.replace(/ /g, '').split('\n').map(r => r.trim()).join('\n')
        if (this.addPhones !== after)
            this.addPhones = after
    }

    startSendMsg(group) {
        this.activeGroup = group
        this.showSendMsg = true
    }

    async sendMsg() {
        createRequest(`/chat-api/whatsapp-group/${this.activeGroup._id}/message`, 'post', {}, {
            message: this.msg
        }).send().then().catch()
        await this.$message.info('Registered, please wait for ChatAPI')
        this.showSendMsg = false
    }

    handleDrawerClose() {
        if (this.newGroup) {
            this.cancelCreateNewGroup()
        }
        else {
            this.activeGroup = new WhatsAppGroup()
            this.cancelCreateNewGroup()
        }
    }

    undoMemberAddRemove(member: WhatsAppGroupMember) {
        if (member.tobe_added) {
            this.removePhone(member.phone)
        }
        else if (member.tobe_removed) {
            this.addPhones = member.phone
            this.submitGroup()
        }
    }

    async saveClass() {
        let bodyOfflineClasses = this.activeGroup.classes.map((c) => {
            return {
                course_id: c.course_id,
                classes: c.classes
            }
        })

        if (this.editingGroupClassesIndex !== -1) {
            let existingIndex = bodyOfflineClasses.findIndex((c) => c.course_id === this.courseId)
            if (existingIndex !== -1 && existingIndex !== this.editingGroupClassesIndex) {
                // Changed the groupClasses' course id, and it is the same with another existing groupClasses.
                bodyOfflineClasses.splice(this.editingGroupClassesIndex, 1)
                existingIndex = bodyOfflineClasses.findIndex((c) => c.course_id === this.courseId)
                bodyOfflineClasses.splice(existingIndex, 1, {
                    course_id: this.courseId,
                    classes: this.classes
                })
            }
            else {
                bodyOfflineClasses.splice(this.editingGroupClassesIndex, 1, {
                    course_id: this.courseId,
                    classes: this.classes
                })
            }
        }
        else {
            const existingIndex = bodyOfflineClasses.findIndex((c) => c.course_id === this.courseId)
            if (existingIndex !== -1) {
                bodyOfflineClasses.splice(existingIndex, 1)
            }
            bodyOfflineClasses.push({
                course_id: this.courseId,
                classes: this.classes
            })
        }

        await createRequest(`/chat-api/whatsapp-group/${this.activeGroup._id}/classes`, 'post', {}, {
            classes: bodyOfflineClasses
        }).send().then().catch()
        await this.updateGroups(this.currentPage)
        this.$message.success('Changed')

        // const ts = this.course.offline_data.classes.find(c => c.id === this.activeGroup.class)
        // this.activeGroup.class_title = ts? ts.title : ''

        this.showEditCourse = false
    }

    editGroupClasses(groupClass: GroupClasses | undefined = undefined) {
        if (groupClass) {
            this.courseId = groupClass.course_id
            this.classes = groupClass.classes
            this.editingGroupClassesIndex = this.activeGroup.classes.findIndex((c) => c.course_id === this.courseId)
        }
        else {
            this.courseId = ""
            this.course = new Course()
            this.classes = []
            this.editingGroupClassesIndex = -1
        }
        this.showEditCourse = true
    }

    removeGroupClasses(groupClass: GroupClasses) {
        this.activeGroup.classes = this.activeGroup.classes.filter((c) => c.course_id !== groupClass.course_id)
        let bodyOfflineClasses = this.activeGroup.classes.map((c) => {
            return {
                course_id: c.course_id,
                classes: c.classes
            }
        })
        createRequest(`/chat-api/whatsapp-group/${this.activeGroup._id}/classes`, 'post', {}, {
            classes: bodyOfflineClasses
        }).send().then().catch()
        this.$message.success('Removed')
    }

    @Watch('showSendMsg', {deep: false})
    watchShowSendMsg(newVal, oldVal) {
        if (!newVal) {
            this.activeGroup = new WhatsAppGroup()
        }
    }

}

