import Serializable from '~/utils/serializable';
import {NoteLanguage, weekDayOptions} from "~/components/data-class/data-class";
import moment from 'moment';

export enum OfflineClassType {
    IN_PERSON,
    LIVE,
    VIDEO,
    RECORDING
}

export class OfflineClass extends Serializable {
    _id: string
    course_id: string;
    id: number = 0; // Equals to deprecated TimeSlot id.
    title: string = "";
    version: number = 1;

    location: string = "";
    address: string = "";
    language: NoteLanguage = NoteLanguage.CHINESE;
    type: OfflineClassType = OfflineClassType.LIVE;
    hidden: boolean = false;

    whatsapp_link: string = "";
    zoom_link: string = "";

    quota: number = 0;
    preserved: number = 0;
    applied: number = 0;
    paid: number = 0;
    min_applied: number = 0;
    quota_percent: number = 0;

    noti: boolean = false;

    fixed_time: boolean = true;
    fixed_length: boolean = true;
    form: number[] = []
    formTemplate: { icon: string, desc: string } | null = null
    time: number = 0;
    length: number = 0;

    lessons: OfflineLesson[] = [];
    events: OfflineEvent[] = [];
    start: number = 0;
    end: number = 0;

    schedule: ClassSchedule[] = []

    renewal_time: number = 0;

    static noteLanguageTypeToDisplayString(t: NoteLanguage): string {
        switch (t) {
            case NoteLanguage.NONE:
                return "不設筆記"
            case NoteLanguage.EITHER:
                return "供中英筆記選擇"
            case NoteLanguage.CHINESE:
                return "只有中文筆記"
            case NoteLanguage.ENGLISH:
                return "只有英文筆記"
            case NoteLanguage.BOTH:
                return "中英對照筆記"
        }
    }

    static offlineClassTypeToDisplayString(t: OfflineClassType): string {
        switch (t) {
            case OfflineClassType.IN_PERSON:
                return "實體班次"
            case OfflineClassType.LIVE:
                return "直播班次"
            case OfflineClassType.VIDEO:
                return "Video班次 (已棄用)"
            case OfflineClassType.RECORDING:
                return "錄播班次"
        }
    }

    static offlineClassTypeToDisplaySimpleString(t: OfflineClassType): string {
        switch (t) {
            case OfflineClassType.IN_PERSON:
                return "真人班"
            case OfflineClassType.LIVE:
                return "直播班"
            case OfflineClassType.VIDEO:
                return "Video班"
            case OfflineClassType.RECORDING:
                return "錄播班"
        }
    }

    static offlineClassScheduleToMessageString(offlineClass: OfflineClass) {
        if (offlineClass.type === OfflineClassType.VIDEO)
            return "----";
        const lookup = "日_一_二_三_四_五_六".split("_");
        const wLookup = (weekday: number) => lookup[weekday];

        function tsToText(ts: { start: number, end: number }) {
            return `${moment(ts.start).format("HH:mm")} - ${moment(ts.end).format("HH:mm")}`;
        }

        function dates(cs: ClassSchedule) {
            if (cs.day)
                return [moment(cs.day)];
            else
                return offlineClass.lessons.map(l => moment(l.start)).filter(m => cs.weekdays.includes(m.weekday()));
        }

        return offlineClass.schedule.map(s =>
            `星期${s.weekdays.map(wLookup).join("、")}｜` +
            s.timeslots.map(tsToText).join(" & ") + "：" +
            dates(s).map(d => d.format("D/MM") + (s.weekdays.length !== 1 ? `(${wLookup(d.weekday())})` : '')).join(", ")
        ).join("；");
    }

    static cleanForCreation(offlineClass: OfflineClass) {
        // Remove redundant keys from object creation.
        if (!offlineClass.fixed_time) {
            delete offlineClass['time']
        }
        if (!offlineClass.fixed_length) {
            delete offlineClass['length']
        }
        for (const key of ['version', 'preserved', 'applied', 'paid', 'quota_percent', 'noti', 'start', 'end', '_id', 'course_id', 'language']) {
            delete offlineClass[key]
        }
        return offlineClass
    }

    static offlineClassCalcSchedule(offlineClass: OfflineClass) {
        const sameHourAndMinutes = function (timeStampA: number, timeStampB: number): boolean {
            return new Date(timeStampA).getHours() === new Date(timeStampB).getHours() && new Date(timeStampA).getMinutes() === new Date(timeStampB).getMinutes()
        }

        // Map class details data to ClassSchedule.

        // Lessons were sorted by date already.
        const offlineLessonDict: { [key: number]: OfflineLesson[] } = offlineClass.lessons.reduce((prev, current) => {
            const weekday = new Date(current.start).getDay()
            if (!prev[weekday]) {
                prev[weekday] = []
            }
            prev[weekday].push(current)
            return prev
        }, {})

        const allLessonsExactTimeSlotDict = Object.keys(offlineLessonDict).reduce((allLessonsExactTimeSlotDict, weekday) => {
            const lessons: OfflineLesson[] = offlineLessonDict[weekday]

            // All lessons have the exact time slot for this week day.
            for (const [i, current] of lessons.entries()) {
                if (i != 0) {
                    const prev = lessons[i - 1]
                    if (prev.timeslots.length !== current.timeslots.length) {
                        allLessonsExactTimeSlotDict[weekday] = false
                        return allLessonsExactTimeSlotDict
                    }

                    for (const [j, timeSlot] of prev.timeslots.entries()) {
                        if (!(sameHourAndMinutes(timeSlot.start, current.timeslots[j].start) && sameHourAndMinutes(timeSlot.end, current.timeslots[j].end))) {
                            allLessonsExactTimeSlotDict[weekday] = false
                            return allLessonsExactTimeSlotDict
                        }
                    }
                }
            }

            allLessonsExactTimeSlotDict[weekday] = true
            return allLessonsExactTimeSlotDict
        }, {})

        if (Object.values(allLessonsExactTimeSlotDict).includes(false)) {
            offlineClass.schedule = offlineClass.lessons.map((lesson) => {
                const schedule = new ClassSchedule()
                schedule.day = lesson.start
                schedule.weekdays = [new Date(lesson.start).getDay()]
                schedule.timeslots = lesson.timeslots.map((timeslot) => {
                    return {start: timeslot.start, end: timeslot.end}
                })
                return schedule
            })
        } else {
            let weekdays = Object.keys(offlineLessonDict)

            // Reset schedule, because schedule exists in edited classes already.
            offlineClass.schedule = []

            while (weekdays.length > 0) {

                const schedule = new ClassSchedule()

                for (const weekday of weekdays) {
                    // All lessons have the same timeslots, use the first one.
                    const currentTimeSlots: LessonTimeSlot[] = offlineLessonDict[weekday][0].timeslots

                    if (schedule.timeslots.length === 0) {
                        schedule.timeslots = currentTimeSlots.map((timeslot) => {
                            return {start: timeslot.start, end: timeslot.end}
                        })
                        schedule.weekdays = [parseInt(weekday)]
                    } else if (schedule.timeslots.length === currentTimeSlots.length) {
                        // Group weekdays with the same time slots.
                        if (schedule.timeslots.reduce((exactTimeSlots, timeSlot, j) => {
                            return exactTimeSlots && (sameHourAndMinutes(timeSlot.start, currentTimeSlots[j].start) && sameHourAndMinutes(timeSlot.end, currentTimeSlots[j].end))
                        }, true)) {
                            schedule.weekdays.push(parseInt(weekday))
                        }
                    }
                }

                offlineClass.schedule.push(schedule)

                // Remove grouped weekdays.
                weekdays = weekdays.filter((weekday) => !schedule.weekdays.includes(parseInt(weekday)))
            }
        }

        return offlineClass
    }
}

export class LessonTimeSlot {
    start: number = 0
    end: number = 0
    stream_end?: boolean

    constructor(start = 0, end = 0) {
        this.start = start
        this.end = end
    }
}

export class OfflineLesson extends Serializable {
    timeslots: LessonTimeSlot[] = []

    start: number = 0
    end: number = 0

    disabled: boolean = false
}

export class OfflineEvent {
    title: string;
    time: number;
}

export class ClassSchedule {
    weekdays: number[] = []
    timeslots: {
        start: number,
        end: number
    }[] = []
    day?: number

    static scheduleToStringRepresentation(schedule: ClassSchedule): string {
        const timeSlotsString = schedule.timeslots.map((timeSlot) => {
            return [new Date(timeSlot.start), new Date(timeSlot.end)].map((date) => date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')).join(' - ')
        }).join(' & ')
        return '星期' +
            schedule.weekdays.map((weekday) => weekDayOptions.find((w) => w.value === weekday)!.label.replace('星期', '')).join('、') +
            '｜' + timeSlotsString
    }
}



