

















import {Component, Vue, Prop} from "vue-property-decorator";
import {ClassSchedule, OfflineClass, OfflineLesson} from '~/components/course/offline-course-model'
import {weekDayOptions} from "~/components/data-class/data-class";

@Component({
    components: {

    }
})
export default class OfflineClassStructuredDisplay extends Vue {
    name: "offline-class-structured-display"

    @Prop()offlineClass: OfflineClass

    ClassSchedule = ClassSchedule

    lessonDateToCalendarString(lesson: OfflineLesson, singleWeekday: boolean) {
        const date = new Date(lesson.start)
        let calendarString = date.getDate() + '/' + (date.getMonth() + 1)
        if (!singleWeekday) {
            calendarString = calendarString + '(' + weekDayOptions.find((w) => w.value === date.getDay())!.label.replace('星期', '') + ')'
        }
        return calendarString
    }
}
