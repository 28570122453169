import Vue from 'vue'
import Component from 'vue-class-component'
import {Dict} from '~/utils/misc'
import {ElLoadingComponent} from 'element-ui/types/loading'

@Component
export default class LoadingMixin extends Vue {
    loadingDict: Dict<ElLoadingComponent> = {}

    startLoading(target: string, text?: string) {
        const op: any = {target}
        if(text)
            op.text = text

        this.loadingDict[target] = this.$loading(op)
    }

    stopLoading(target?: string) {
        if(target) {
            const l = this.loadingDict[target]
            if(l) {
                l.close()
                delete this.loadingDict[target]
            }
        } else {
            for(const l of Object.values(this.loadingDict)) {
                l.close()
            }
            this.loadingDict = {}
        }
    }
}
